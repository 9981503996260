<template>
  <section class="our-team">
    <TitleBar v-if="data" :data="data" />
    <div class="content-wrapper">
      <div class="flex-grid">
        <div
          class="item"
          v-for="member in data.teamMembersCollection.items"
          :key="member.sys.id"
        >
          <router-link
            class="link-wrapper"
            :to="`/member/${nameToSlug(member.name)}`"
          >
            <img class="headshot" :src="member.headshot.url" alt="headshot" />
            <div class="member-bar"></div>
            <h4>{{ member.name }}</h4>
            <h5 class="secondary">{{ member.title }}</h5>
            <div class="bio-link secondary">
              <img src="../assets/read-bio-icon.svg" width="15" />Read bio
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TitleBar from "./TitleBar.vue";

export default {
  name: "OurTeam",
  components: {
    TitleBar,
  },
  props: {
    data: Object,
  },
  setup() {
    function nameToSlug(name) {
      return name
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    }

    return {
      nameToSlug,
    };
  },
};
</script>

<style scoped lang="scss">
section.our-team {
  width: 100%;
  align-items: center;

  .content-wrapper {
    width: 100%;
    .flex-grid {
      width: 85%;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      justify-items: center;

      @include tablet {
        width: 100%;
      }

      .item {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .link-wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          .headshot {
            margin-bottom: 0;
            height: 10em;
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
            transition: 0.15s ease-in-out;
          }

          .member-bar {
            height: 5px;
            width: 80%;
            background-color: $white;
            margin-bottom: 1em;

            @include tablet {
              width: 90%;
            }

            @include large-mobile {
              width: 15em;
            }
          }

          h4 {
            margin-bottom: 0.5em;
          }

          h5 {
            margin-bottom: 1em;
          }

          .bio-link {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 85%;

            img {
              margin-bottom: 0;
              margin-right: 0.5em;
              margin-top: 2px;
            }

            color: $secondary;
            text-decoration: none;
            transition: color 0.15s ease;
          }

          &:hover {
            .headshot {
              -webkit-filter: grayscale(0%);
              filter: grayscale(0%);
            }

            .bio-link {
              color: $white;
            }
          }
        }
      }
    }
  }
}
</style>
